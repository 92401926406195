import Token from "./ABI/FOMOSHIB.json"
import { ethers } from 'ethers';
import { Message } from 'element-ui';

let contractaddr = "0xF4589A617FadB00B2958856e5B950998c3B62Cd4"
let Provider
let TokenContract
let Account

const msg = {
    destorySuccess: {
        zh: '销毁成功',
        en: 'Destory Successfully'
    },
    destoryFail: {
        zh: '销毁失败',
        en: 'Destory fail'
    }
}
export const getAttr = () => {
    return contractaddr;
}

export const InitWeb = async () => {
    try {
        if (isMetaMaskInstalled()) {
            Provider = new ethers.providers.Web3Provider(window.ethereum);
            let chainID = await Provider.getNetwork()
            //console.log("chainID:", chainID)
            if (chainID.chainId == 97) {
                await window.ethereum.enable();
                let Signer = Provider.getSigner();
                TokenContract = new ethers.Contract(contractaddr, Token.abi, Signer)
                return await Provider.listAccounts().then((accounts) => {
                    Account = accounts[0].toString();
                    //console.log('account', Account)
                    return Account;
                })
            } else {
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x61' }],//0x38
                });
                Provider = new ethers.providers.Web3Provider(window.ethereum);
                let chainID1 = await Provider.getNetwork()
                if (chainID1.chainId == 97) {
                    await window.ethereum.enable();
                    let Signer = Provider.getSigner();
                    TokenContract = new ethers.Contract(contractaddr, Token.abi, Signer)
                    return await Provider.listAccounts().then((accounts) => {
                        Account = accounts[0].toString();
                        //console.log('account', Account)
                        return Account;
                    })
                } else {
                    return false;
                }
            }
        }
    } catch (err) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (err.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainId: '0x38',
                            chainName: 'BSC Mainnet',
                            rpcUrls: ['https://bsc-dataseed2.binance.org/'],
                        },
                    ],
                });
            } catch (addError) {
                // handle "add" error
                Message.error(addError);
            }
        }
        // handle other "switch" errors
        Message.error(err);
    }
}

export const tokenInfo = async () => {
    try {
        if (isMetaMaskInstalled()) {
            let inittotalSupply = await TokenContract.inittotalSupply() / 10 ** 18
            let totalSupply = await TokenContract.totalSupply() / 10 ** 18
            let getUnderpinningPrice = await TokenContract.getUnderpinningPrice() / 10 ** 18
            let balance = await TokenContract.balanceOf(Account) / 10 ** 18
            console.log(getUnderpinningPrice.toFixed(18) + " USDT", balance)
            return { totalNum: inittotalSupply.toString(), leftNum: totalSupply.toString(), destoryNum: inittotalSupply - totalSupply, balance: balance, price: getUnderpinningPrice.toFixed(18) + " USDT" }
        }
    } catch (err) {
        Message.error(err);
        console.log("Error: ", err)
    }
}

export const activeBurn = async (_value) => {
    try {
        if (isMetaMaskInstalled()) {
            let value = _value * 10 ** 18
            TokenContract.activeBurn(value.toString())
            // let topic = ethers.utils.id("ActiveBurn(address,uint256,uint256,uint256)");
            // let filter = {
            //     address: Account,
            //     topics: [topic]
            // }
            // A filter from anyone to me
            let filterToMe = TokenContract.filters.ActiveBurn(Account);

            Provider.once(filterToMe, async (result) => {
                console.log("result:", result)
                Message.success(msg.destorySuccess[localStorage.getItem("languageStorage")]);
                return true;
            })
        }
    } catch (err) {
        Message.success(msg.destoryFail[localStorage.getItem("languageStorage")]);
    }
}


const isMetaMaskInstalled = () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
};


//Now we check to see if MetaMask is installed
if (!isMetaMaskInstalled()) {
    alert("Click here to install MetaMask!")
    window.location.href="https://metamask.io/"
} else {
    window.ethereum.on('accountsChanged', function (accounts) {
        // Time to reload your interface with accounts[0]!
        window.location.reload()
        console.log("account", accounts)
    });
}

