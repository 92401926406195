module.exports = {
  home: {
    wrong: '错误的链',
    copySuccess: '复制成功',
    connect: '连接钱包',
    intro: '项目介绍 & 生态系统',
    buy: '购买',
    bannerText1: 'Fomo Shiba Inu一个去中心化Meme代币社区，一个充满潜力的生态系统。',
    bannerText2: 'Fomo Shiba Token项目是部署在BSC链上的一个社区自治代币。社区成员飞速发展中!',
    howToBuy: '如何购买',
    readWhite: '阅读白皮书',
    introDes: 'Fomo Shiba Inu，通缩代币总量1000万亿。意喻不可错过的柴犬币。每笔交易：3%燃烧，3%添加FOMOSHIB&USDT流动性到PancakeSwap，3%交换为USDT进入托管合约，代币总量80%通过流动性锁定到pancake，20%总量团队预留。',
    about: '关于托管合约：',
    aboutDes: '托管合约属于FOMOSHIB独有机制。确保了FOMOSHIB拥有真正的价值。托底合约专门存放USDT，用户可以选择销毁FomoDoge代币从托底合约中获取到当前销毁数量占FOMOSHIB代币总量的比例相对应的USDT。此机制保证了代币的最低价格，随着交易次数增多，存在托管合约中的USDT越多，FOMOSHIB代币总量越少。每个FOMOSHIB可以从托底合约销毁兑换的USDT数量也就越多。',
    cardLine1: '80%流动性锁定',
    cardLine2: '到 Pancakeswap',
    cardLine3: '20%创始团队',
    cardLine4: '后续应用开发',
    fomoIntro: 'FomoShiba Inu代币是我们的基础货币，允许投资者在钱包中持有数百万、数十亿甚至万亿的代币。',
    fomoDes1: '对于合约部署者将放弃所有管理员权限。',
    fomoDes2: '后期团队将开发一款NFT+盲盒的卡牌游戏,作为FOMOSHIB的上层应用。',
    buyWay: 'SHIB 最好通过 pancakeswap 购买和销售。',
    buySug: 'FOMOSHIB是一个分散的实验，因此，我们总是鼓励使用DEX交易。如果您选择使用CEX，请记住确保它既安全又可靠。',
    buyAddress: '合约地址',
    totalNum: '初始总量',
    leftNum: '剩余总量',
    destoryNum: '已销毁数',
    destory: '销毁',
    pleaseEnter: '请输入销毁数量',
    balance: '你的代币数量为：',
    price: '每枚代币价格约为 ≈ ',
    startGuide: '快速入门指南',
    stepTitle1: '创建 MetaMask 钱包',
    stepDes1: '使用台式计算机或iOS/Android移动设备创建MetaMask钱包。这将允许您购买、出售、发送和接收FOMOSHIB。',
    stepTitle2: '连接你的钱包到pancakeswap',
    stepDes2: '通过单击“连接到钱包”并选择MetaMask，将您的钱包访问到pancakeswap。',
    stepTitle3: '发送BNB到你的钱包',
    stepDes3: '您可以直接在MetaMask上购买币安币（BNB），也可以从Coinbase、Binance等交易所将其转移到MetaMask钱包。转移BNB时，请确保使用ERC-20网络。',
    stepTitle4: '将BNB换成FOMOSHIB',
    stepDes4: '您可以在有可用的 BNB 后立即开始交换！按“选择令牌”并输入令牌地址或在令牌列表中搜索它。',
    art: '艺术创作',
    artDes: '这里将在以后放上关于FOMOSHIB的艺术创作。创作的作品来自于开发团队以及FOMOSHIB的爱好者。',
    community: '社区',
    communityDes: '如果您想向开发者捐款，请在此处发送。',
    or: 'or',
    communityThank: '谢谢你的支持，该账户所收捐款会用于社区发展',
    copy: '复制',
    starDes: '我们的社区每天都在变得更加强大。请关注我们的社交平台以获取最新、准确的 FOMOSHIB 信息。',
    linkDes: '使用下面的链接，您可以与 FomoShibArmy 的 100 万多名其他成员一起加入我们的各个小组。',
    clickDes: '点击这里搜索您当前语言及地区的Telegram群！',
    group: '群'
  }
};